import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Counter = () => {
  const [count, setCount] = useState(0);

  // Obtener el valor del contador desde el backend
  useEffect(() => {
    axios
      .get('https://meetingcounter.foodiiz.com/counter.php')
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error('Error fetching the count:', error);
      });
  }, []);

  // Incrementar el contador
  const increment = () => {
    axios
      .post('https://meetingcounter.foodiiz.com/counter.php', new URLSearchParams({ action: 'increment' }))
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error('Error incrementing the count:', error);
      });
  };

  // Decrementar el contador
  const decrement = () => {
    axios
      .post('https://meetingcounter.foodiiz.com/counter.php', new URLSearchParams({ action: 'decrement' }))
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error('Error decrementing the count:', error);
      });
  };
  return (
    <div className="counter-container">
      <img src="/subaio-logo.jpeg" alt="Subaio Logo" className="subaio-logo" />
      <div className="counter-display">
        <h2>Meetings</h2>
        <p>{count}</p>
      </div>
      <div className="counter-buttons">
        <button onClick={decrement} className="counter-btn" type="button">-</button>
        <button onClick={increment} className="counter-btn" type="button">+</button>
      </div>
    </div>
  );
};

export default Counter;
